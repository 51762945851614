<template>
  <div id="policy-container">
    <h1>Genel Segmentasyon</h1>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }"><i class="icon-home"></i></el-breadcrumb-item>
        <el-breadcrumb-item>Ana Sayfa</el-breadcrumb-item>
        <el-breadcrumb-item>Genel Segmentasyon</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="form-container">
      <el-form :model="form" label-width="120px">
        <div class="form-row">
          <!-- app type seçilicek psikolog diyetisyen doktor olarak select box olucak -->
         <!--  <el-form-item label="Uygulama">
            <el-select v-model="form.AppType" placeholder="Seçiniz">
              <el-option label="Doktor" :value="0"></el-option>
              <el-option label="Psikolog" :value="1"></el-option>
              <el-option label="Diyetisyen" :value="2"></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="Türü">
            <el-switch v-model="form.IsDiscount" active-text="İndirim" inactive-text="Artış" active-color="#234A98"></el-switch>
          </el-form-item>

          <el-form-item label="Tip">
            <el-switch v-model="form.IsDiscountPercent" active-text="Yüzdeli" inactive-text="Fiyat" active-color="#234A98"></el-switch>
          </el-form-item>
        </div>

        <el-form-item :label="form.IsDiscountPercent ? 'Yüzde' : 'Tutar'">
          <el-input type="number" v-model.number="form.Discount"></el-input>
        </el-form-item>

        <el-form-item label="Ücretsiz Sayısı">
          <el-input type="number" v-model.number="form.FreeCount"></el-input>
        </el-form-item>
        <div class="form-row">
          <el-form-item label="Tarih Kontrolü">
            <el-switch v-model="form.CheckDates" active-text="Yapılsın" inactive-text="Yapılmasın" active-color="#234A98"></el-switch>
          </el-form-item>
          <el-form-item label="Tarih">
            <el-date-picker v-model="DateRange" :default-value="[new Date(), new Date()]" align="center" format="dd.MM.yyyy" type="daterange" range-separator="-" start-placeholder="Başlangıç" end-placeholder="Bitiş Tarihi" prefix-icon="icon-calendar" :disabled="form.CheckDates == false"> </el-date-picker>
          </el-form-item>
        </div>
        <div class="form-row between">
          <el-form-item label="Durum">
            <el-switch v-model="form.IsActive" active-text="Aktif" inactive-text="Pasif" active-color="#234A98"></el-switch>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="addUpdateSegmentation">Kaydet</el-button>
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "GeneralSegmentation",
  data() {
    return {
      form: {
        Discount: null,
        IsActive: true,
        CheckDates: false,
        SegmentStartDate: null,
        SegmentEndDate: null,
        IsDiscount: true,
        IsDiscountPercent: false,
        FreeCount: null,
        AppType: 0,
      },
      DateRange: [],
      ValidationErrors: [],
    };
  },
  created() {
    this.getGeneralSegment();
  },
  methods: {
    async getGeneralSegment() {
      var res = await this.$client.post("/admin/GetGeneralSegment", {});
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
        this.ValidationErrors = res.data.ValidationErrors;
      } else {
        if (res.data.Data != null) {
          this.form = res.data.Data;
          this.DateRange = [this.$moment(this.form.SegmentStartDate).format("YYYY-MM-DD"), this.$moment(this.form.SegmentEndDate).format("YYYY-MM-DD")];
        }
      }
    },
    async addUpdateSegmentation() {
      this.ValidationErrors = [];

      this.form.SegmentStartDate = null;
      this.form.SegmentEndDate = null;

      if (this.form.CheckDates) {
        if (this.DateRange != null && this.DateRange[0] != null && this.DateRange[1] != null) {
          this.form.SegmentStartDate = this.$moment(this.DateRange[0]).utc(true);
          this.form.SegmentEndDate = this.$moment(this.DateRange[1]).utc(true);
        } else {
          this.$message.error("Tarih kontrolü seçilmiş,fakat Başangıç ve Bitiş tarihi girilmemiş!");
          return;
        }
      }

      var res = await this.$client.post("/admin/SetGeneralSegment", this.form);

      if (res.data.HasError) {
        this.$message.error(res.data.Message);
        this.ValidationErrors = res.data.ValidationErrors;
      } else {
        this.$message.success(res.data.Message);
        this.ValidationErrors = [];
      }
    },
  },
};
</script>

<style lang="less" scoped>
.form-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  &.between {
    justify-content: space-between;
  }
}
</style>
